import Box from "../../../common/components/Box";
import Heading from "../../../common/components/Heading";
import Text from "../../../common/components/Text";
import Container from "../../../common/components/UI/Container";
import { INTRO_DATA } from "../../../common/data/WebApp";
import { Link } from "gatsby";

import React from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import IntroArea, { Row, Col } from "./intro.style";
const Intro = () => {
  const { blockTitle, image } = INTRO_DATA;
  const { title, text, button } = blockTitle;
  return (
    <IntroArea>
      <Container>
        <Col>
          <Box className="blockTitle">
            <Heading as="h2" content={title} />
            <Text as="p" content={text} />
            <Link className="button" to={button.link}>
              <span>
                {button.label}
                <Icon icon={androidArrowForward} size={16} />
              </span>
            </Link>
          </Box>
        </Col>
      </Container>
    </IntroArea>
  );
};

export default Intro;
